<div *ngIf="app === 'ampifyme'" class="Polaris-EmptyState pt-5 pb-5 my-5">
  <div class="Polaris-EmptyState__Section">
    <div class="Polaris-EmptyState__DetailsContainer">
      <div class="Polaris-EmptyState__Details">
        <div class="Polaris-TextContainer">
          <p class="Polaris-DisplayText Polaris-DisplayText--sizeMedium">
            You discovered a new feature.
          </p>
          <div class="Polaris-EmptyState__Content">
            <p>
              This feature is available in our {{plan}} plan.
              All our paid plans have 56 days free trial.
            </p>
            <p class="mt-2 mb-2">
              Upgrade now and get all the benefits from AmpifyMe.
            </p>
          </div>
        </div>
        <div class="Polaris-EmptyState__Actions">
          <div class="Polaris-Stack Polaris-Stack--alignmentCenter">
            <div class="Polaris-Stack__Item">
              <button (click)="upgrade()" type="button"
                class="Polaris-Button Polaris-Button--primary Polaris-Button--sizeLarge">
                <span class="Polaris-Button__Content">
                  <span class="Polaris-Button__Text">
                    Try with 56 days free trial
                  </span>
                </span>
              </button>
            </div>

            <!-- <div class="Polaris-Stack__Item">
              <button (click)="seeAllPlans()" type="button" class="Polaris-Button Polaris-Button--plain"
                data-polaris-unstyled="true">
                <span class="Polaris-Button__Content">
                  <span class="Polaris-Button__Text">See all plans</span>
                </span>
              </button>
            </div> -->


          </div>
        </div>
      </div>
    </div>
    <div class="Polaris-EmptyState__ImageContainer">
      <img src="/assets/amp/images/upgrade.svg" role="presentation" alt="" class="Polaris-EmptyState__Image">
    </div>
  </div>
</div>



<div *ngIf="app === 'progressifyme'" class="Polaris-EmptyState pt-5 pb-5 my-5">
  <div class="Polaris-EmptyState__Section">
    <div class="Polaris-EmptyState__DetailsContainer">
      <div class="Polaris-EmptyState__Details">
        <div class="Polaris-TextContainer">
          <p class="Polaris-DisplayText Polaris-DisplayText--sizeMedium">
            You discovered a new feature.
          </p>
          <div class="Polaris-EmptyState__Content">
            <p>

              This feature is available in our {{plan}} plan.
              Our paid plan have 28 days free trial.

            </p>
            <p class="mt-2 mb-2">
              Upgrade now and get all the benefits from PWA by AmpifyMe.
            </p>
          </div>
        </div>
        <div class="Polaris-EmptyState__Actions">
          <div class="Polaris-Stack Polaris-Stack--alignmentCenter">
            <div class="Polaris-Stack__Item">
              <a href="{{upgradeUrl}}basic">
              <button   type="button"
                class="Polaris-Button Polaris-Button--primary Polaris-Button--sizeMedium">
                <span class="Polaris-Button__Content">
                  <span class="Polaris-Button__Text">
                    Upgrade
                  </span>
                </span>
              </button>
            </a>
            </div>

            <!-- <div class="Polaris-Stack__Item">
              <button (click)="seeAllPlans()" type="button" class="Polaris-Button Polaris-Button--plain"
                data-polaris-unstyled="true">
                <span class="Polaris-Button__Content">
                  <span class="Polaris-Button__Text">See all plans</span>
                </span>
              </button>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="Polaris-EmptyState__ImageContainer">
      <img src="/assets/pwa/images/upgrade.svg" role="presentation" alt="" class="Polaris-EmptyState__Image">
    </div>
  </div>
</div>